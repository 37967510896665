<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">网格整改分析</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div>提交时间：</div>
				<el-date-picker style="width: 400px;" class="m-1" size="small" @change="changeTime" v-model="time"
					type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<div class="d-flex align-items-center m-1">
					<div class="label ">单位：</div>
					<el-select @change="handleFirstChange" v-model="search.first_id" placeholder="请选择支队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleSecondChange" v-model="search.second_id" placeholder="请选择大队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleThirdChange" v-model="search.third_id" placeholder="请请选择街道">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
				</div>
				<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search"></el-button>
				<el-button @click="handleExport" class="ml-2" type="primary" size="mini">导出</el-button>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%" :default-expand-all="true">
				<el-table-column align="center" label="单位/网格">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.name}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="登记总数量">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.total_count}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="整改合格总数量">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.edit_count}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="整改次数">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.edit_number}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="总整改率">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.edit_percent}} %</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="有住人场所">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.person_count}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="有住人整改合格数量">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.person_eligible_count}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="有住人整改率">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.person_percent}} %</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="无住人场所数量">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.not_person_count}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="无住人整改合格数量">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.not_person_eligible_count}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="无住人场所整改率">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.not_person_percent}} %</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import appMap from '@/components/app-map.vue';
	export default {
		inject: ['layout'],
		components: {
			appMap,
		},
		data() {
			return {
				lists: [],
				firstList: [],
				secondList: [],
				thirdList: [],
				search: {
					s: 'store/statistics/check',
					first_id: 0,
					second_id: 0,
					third_id: 0,
					start_time: null,
					end_time: null,
				},
				time: [],
			}
		},
		created() {
			this.getUnitList();
		},
		methods: {
			changeTime() {
				if (this.time) {
					this.search.start_time = this.time[0];
					this.search.end_time = this.time[1];
				} else {
					this.search.start_time = null;
					this.search.end_time = null;
				}
				this.toSearch();
			},
			handleExport() {
				let params = {
					s: 'store/Statistics/checkExport',
					first_id: this.search.first_id,
					second_id: this.search.second_id,
					third_id: this.search.third_id,
					start_time: this.search.start_time,
					end_time: this.search.end_time,
				};
				this.axios({
					token: true,
					params: params,
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						window.location.href = this.$conf.base_url + 'check_export_list.xls'
					}
				}).catch(err => {})
			},
			handleFirstChange(first_id) {
				this.search.second_id = 0;
				this.search.third_id = 0;
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
					}
				}
			},
			handleSecondChange(second_id) {
				this.search.third_id = 0;
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
					}
				}
			},
			handleThirdChange(third_id) {},
			getUnitList() {
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.firstList = res.data.data
					this.search.first_id = this.firstList[0].unit_id
					this.handleFirstChange(this.search.first_id)
					this.getList()
				}).catch(err => {});
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>
